import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
// import config from '../utils/siteConfig'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location }) => {
    const page = data.ghostPage
    const host = [location.origin].toString()
    // `href="${config.siteUrl}`

    let test = 2
    const slug = [page.slug].toString()
    let image = `/${slug}.png`
    if ( slug === 'about' || slug === 'initiatives') {
        test = 0
    }
    switch (true) {
        case (slug === 'about'):
            test = 0
            break
        case (slug === 'initiatives'):
            test = 0
            break
        case (slug === 'credentials'):
            test = 1
            break
        case (slug === 'jobs'):
            test = 1
            break
        case (slug === 'references'):
            test = 1
            break            
        case (slug === 'skills'):
            test = 1
            break    
        default:
            break;
    }

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />

            <Helmet>
                <style type="text/css">{`${page.codeinjection_head}`}</style>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            
            <Layout>
                {/* Figure Banner */}
                <div className={test === 0 ? null : "hide"}>
                    <div className={test === 0 ? "banner-figure page" : "hide"}
                        style={{ ...page.feature_image && { backgroundImage: `url(${page.feature_image})`}}}></div>
                    <h1 className="page-title">{page.title}</h1>
                </div>
                                    
                {/* Dot Banner */}
                <div className={test === 1 ? null : "hide"}>
                    {image && <img className="banner-dot" src={image} alt={page.title} />}
                    <h1 className="page-title">{page.title}</h1>
                </div>

                {/* No-Image Banner */}
                <div className={test === 2 ? null : "hide"}>
                    <h1 className="page-title">{page.title}</h1>
                </div>

                <div className="container">
                    <article>
                        <div
                            className="load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: page.html.replace(/href="https:\/\/maxderungs.com/g,`href="${host}`) }}
                        />
                    </article>
                </div>

                <div className="container"><br/></div>

            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: 
			{ 
				eq: $slug 
			}
		) {
            ...GhostPageFields
        }
    }
`
